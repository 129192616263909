import { BrowserRouter, Route, Routes, HashRouter as Router } from 'react-router-dom';
import SignIn from "./Login/SignIn";
import SignUp from "./Register/SignUp"; 

export default function App() {
    return (
        <Router>
            <Routes>
                {/* <Route index element={<Home />} /> */}
                <Route path="/" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
            </Routes>
        </Router>
    )
}
