export default function validate(values) {
    let errors = {};
    // if (!values.email) {
    //     errors.email = 'Email address is required';
    // } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    //     errors.email = 'Email address is invalid';
    // }
    if (!values.password) {
        errors.password = 'Password is required';
    }
    else if (values.password.length < 6) {
        errors.password = 'Password must be 6 or more characters';
    }
    if (!values.username) {
        errors.username = "Username is required";
    }
    if (!values.company) {
        errors.company = "Copany Name is required";
    }
    if (!values.firstName) {
        errors.firstName = "FirstName is required";
    }
    if (!values.lastName) {
        errors.lastName = "LastName is required";
    }
    if (!values.phone) {
        errors.phone = "Phone is required";
    }
    else if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(values.phone)) {
        errors.phone = "Phone number is not valid!";
    }
    return errors;
};