import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import logo from "../img/logo.png";
import logoGif from '../img/secure_lock.png'

//import apiService from "../Services/apiServices";
import myServices from '../Services/myServices';

import useForm from "../Services/useForm";
import validate from "./SignUpValidate";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import loginService from '../Services/loginService';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mynnet.com/">
                Mynnet Solution
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme();

export default function SignUp() {
    const [checked, setChecked] = useState(false);
    const [alertProp, setalertProp] = useState({});
    const [client, setClient] = useState({firstName:'test', lastName:'test', Name:'Test'});
    const [subscription, setSubscription] = useState({});
    const signUp = () => {

        var currentdate = new Date();
        values.email = values.username;
        values.DateAdded = currentdate;
        setClient({
            Name: values.company,
            CompanyName: values.company,
            ContactPerson: values.firstName + ' ' + values.lastName,
            PhoneNumber: values.phone,
            Email: values.username,
        })
        loginService().postUser(values)
            .then((response) => {
                if (response.success) {
                    console.log(client)
                    myServices().post('clients', client)
                        .then((response) => {
                            console.log(response.errorMessage)
                            if (response.success) {
                                setSubscription({
                                    Name: response.data.Name,
                                    ClientId: response.data.id
                                })
                                myServices().post('newsubscription', subscription)
                                    .then((response) => {
                                        if (response.success) {
                                            setalertProp({ open: true, message: "New subscription is saved successfully", color: "success" });
                                        }
                                        else {
                                            setalertProp({ open: true, message: "Can't save new subscription", color: "error" });
                                        }
                                    })
                            }
                            else {
                                setalertProp({ open: true, message: "Can't save new client", color: "error" });
                            }
                        })
                }
                else if (response.errorMessage) {
                    var err = response.errorMessage.replace(/[^A-Z]/g, '');
                    if (err.includes("UNIQUEKEY")) {
                        setalertProp({ open: true, message: "This username already taken!", color: "error" });

                    }
                    else {
                        setalertProp({ open: true, message: "We have a problem in user", color: "error" });
                    }
                }
            })
        console.log(values);
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(signUp, validate);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setalertProp({ open: false, message: alertProp.message, color: alertProp.color });
    };
    return (
        <ThemeProvider theme={theme}>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertProp.open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertProp.color} sx={{ width: '100%' }}>
                    {alertProp.message}
                </Alert>
            </Snackbar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(' + logoGif + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        backgroundSize: 'auto ',
                        backgroundPosition: 'center',
                    }}
                >

                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={1} square sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box
                        sx={{
                            mx: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* <img src={logo} id="logo" style={{ height: "70px", marginBottom: "20px" }} /> */}
                        <Typography component="h1" variant="h5">
                            Start a free 30-day trial now, with no obligation
                        </Typography>
                        <Typography variant="h6">
                            No credit card and no installation required.
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            {/* <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="company"
                                label="Company Name"
                                name="company"
                                value={values.company || ''}
                                onChange={handleChange}
                                error={errors.company ? true : false}
                                helperText={errors.company}
                            /> */}
                            <TextField
                                margin="normal"
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                value={values.phone || ''}
                                onChange={handleChange}
                                error={errors.phone ? true : false}
                                helperText={errors.phone}
                            />
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={values.firstName || ''}
                                        onChange={handleChange}
                                        error={errors.firstName ? true : false}
                                        helperText={errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        value={values.lastName || ''}
                                        onChange={handleChange}
                                        error={errors.lastName ? true : false}
                                        helperText={errors.lastName}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                value={values.username || ''}
                                onChange={handleChange}
                                error={errors.username ? true : false}
                                helperText={errors.username}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={values.password || ''}
                                onChange={handleChange}
                                error={errors.password ? true : false}
                                helperText={errors.password}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmpassword"
                                label="Confirm Password"
                                type="confirm password"
                                id="confirmpassword"
                                value={values.password || ''}
                                onChange={handleChange}
                                error={errors.password ? true : false}
                                helperText={errors.password}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign Up
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link href="#/" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}