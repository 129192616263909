import axios from "axios";
import config from "../config/config";

export default function myServices() {
  var baseUrl = "";

  function addAuthorizationHeader() {
    var currentUser = globals().getLogedInUser();
    baseUrl = config?.baseUrl + "myservices/mapi";

    axios.defaults.headers.common["userid"] = `${currentUser?.data?.id || 0}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${currentUser?.token || ""
      }`;
  }

  return {
    test() {
      alert("Return from test function");
    },

    get(tableName) {
      addAuthorizationHeader();
      return axios.get(baseUrl + `/${tableName}`).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    post(tableName, obj) {
      addAuthorizationHeader();
      return axios.post(baseUrl + `/${tableName}`, obj).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    put(tableName, obj) {
      addAuthorizationHeader();
      return axios.put(baseUrl + `/${tableName}`, obj).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    }    
  };
}
