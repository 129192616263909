import axios from 'axios';
import config from '../config/config';
import md5 from 'md5';
import helper from './helper';

export default function loginService() {


    const login = (userName, password) => {
        var rnd = helper().getRandomInt(1000);
        var token = md5(userName + password + rnd).toLowerCase();
        var baseUrl = config.baseUrl + "mylogins/mapi";

        return axios.get(baseUrl + `/login/getauthcode/${userName}/token/${token}/${rnd}`).then(response => {            
            return response.data;
        });
    }

    const logout = () => {
        //delete cokie if any
        helper().deleteCookie('logedInUser');

        //delete local storage if any
        helper().setItemToLocalStorage('logedInUser', '');
        window.location = '#/login';
    };

    const postUser = (obj) => {
        var baseUrl = config.baseUrl + "mylogins/mapi";
        return axios.post(baseUrl + `/users`, obj).then((response) => {
          //console.log(response.data);
          return response.data;
        });
    };

    return {
        login: login,
        logout: logout,
        postUser: postUser
    }
}
