import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import logo from "../img/logo.png";
import logoGif from '../img/secure_lock.png'
import loginService from "../Services/loginService.js";
import useForm from "../Services/useForm";
import validate from "./SignInValidate"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import helper from '../Services/helper';
import config from '../config/config';
import { useEffect } from 'react';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mynnet.com">
                Mynnet solution
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme();



export default function SignInSide() {

    const [checked, setChecked] = useState(true);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const rememberClick = () => setChecked(!checked);
    var baseUrl = config.baseUrl;

    useEffect(() => {
      // setChecked(true);
      }, []);

    const login = () => {

        var redirectUrl = helper().getParameterByName('redirectUrl');
        //alert('debugg');
        var goRedirectUrl = redirectUrl ? redirectUrl : baseUrl;

        loginService()
            .login(values.username, values.password)
            .then(function (response) {
                if (response.success) {
                    //window.location = '#/';
                    if(checked){
                        window.location = goRedirectUrl + "?authcode=" + response.data[0].authCode + "&rememberme=true";
                    }
                    else{
                        window.location = goRedirectUrl + "?authcode=" + response.data[0].authCode;
                    }
                    
                    //window.location.reload();
                } else {
                    setOpen(true);
                    setMessage(response.error.message);
                }
            });
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(login, validate);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <ThemeProvider theme={theme}>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(' + logoGif + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#fff',
                        backgroundSize: 'auto ',
                        backgroundPosition: 'center',
                    }}
                >

                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={1} square sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box
                        sx={{
                            mx: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* <a href="/"><img src={logo} id="logo" style={{ height: "70px", marginBottom: "20px" }} /></a> */}
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Username"
                                name="username"
                                value={values.username || ''}
                                onChange={handleChange}
                                error={errors.username ? true : false}
                                helperText={errors.username}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                value={values.password || ''}
                                onChange={handleChange}
                                error={errors.password ? true : false}
                                helperText={errors.password}ƒ
                                required
                            />
                            <FormControlLabel
                                control={<Checkbox defaultChecked={checked} onClick={rememberClick} color="primary" />}
                                label="Remember me"
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#/signup" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}